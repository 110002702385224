import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    Router,
    // import as RouterEvent to avoid confusion with the DOM Event
    Event as RouterEvent,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError
} from '@angular/router'
import {AccountService} from "./admin/layout/services/account.service";
import { Subscription } from 'rxjs';

interface Account {
    activated: boolean;
    authorities: string[];
    email: string;
    firstName: string;
    langKey: string;
    lastName: string;
    login: string;
    imageUrl: string;
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Fixario';
  account: Account;
  loading = true;
    authSubscription: Subscription;

    constructor(private router: Router,
                private accountService: AccountService) {
        this.router.events.subscribe((e : RouterEvent) => {
            this.navigationInterceptor(e);
        })
    }

    ngOnInit() {

      //  this.getUserInfo();
      //  this.router.navigate(['dashboard']);
    }


   /* getUserInfo() {
        this.accountService.identity()
            .subscribe((account: Account) => {
                    console.log(account);
                    this.account = account;
                },
                err => {
                    console.log(err);
                });
    }

    registerAuthenticationSuccess() {
        this.authSubscription = this.accountService.identity().subscribe((account: Account) => {
                this.account = account;
            });
    }*/


   /* isAuthenticated() {
        return this.accountService.isAuthenticated();
    }*/





    navigationInterceptor(event: RouterEvent){
        if (event instanceof NavigationStart) {
            this.loading = true
        }
        if (event instanceof NavigationEnd) {
            this.loading = false
        }
        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loading = false
        }
        if (event instanceof NavigationError) {
            this.loading = false
        }

    }

   /* ngOnDestroy() {
        this.authSubscription.unsubscribe();
    }*/
}

import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

import { Observable, Subject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { SERVER_API_URL } from '../../../app.constants';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';


interface Account {
    activated: boolean;
    authorities: string[];
    email: string;
    firstName: string;
    langKey: string;
    lastName: string;
    login: string;
    imageUrl: string;
}

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userIdentity: Account;
  private authenticated = false;
  private authenticationState = new Subject<any>();
  private accountCache$: Observable<Account>;

  constructor(private sessionStorage: SessionStorageService, private http: HttpClient, private $localStorage: LocalStorageService) {}

  fetch(): Observable<Account> {
    return this.http.get<Account>(SERVER_API_URL + 'api/account');
  }

  save(account: Account): Observable<Account> {
    return this.http.post<Account>(SERVER_API_URL + 'api/account', account);
  }

  authenticate(identity) {
    this.userIdentity = identity;
    this.authenticated = identity !== null;
    this.authenticationState.next(this.userIdentity);
  }

 /* hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
      return false;
    }

    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }

    return authorities.some((authority: string) => this.userIdentity.authorities.includes(authority));
  }*/

  identity(force?: boolean): Observable<Account> {
    if (force) {
      this.accountCache$ = null;
    }

    if (!this.accountCache$) {
      this.accountCache$ = this.fetch().pipe(
        tap(
          account => {
            if (account) {
              this.userIdentity = account;
              this.authenticated = true;
              // After retrieve the account info, the language will be changed to
              // the user's preferred language configured in the account setting
              this.$localStorage.store('login', account);
              if (this.userIdentity.langKey) {
                const langKey = this.sessionStorage.retrieve('locale') || this.userIdentity.langKey;
                //this.languageService.changeLanguage(langKey);
              }
            } else {
              this.userIdentity = null;
              this.authenticated = false;
            }
            this.authenticationState.next(this.userIdentity);
          },
          () => {
            this.userIdentity = null;
            this.authenticated = false;
            this.authenticationState.next(this.userIdentity);
          }
        ),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  isIdentityResolved(): boolean {
    return this.userIdentity !== undefined;
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  getImageUrl(): string {
    return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
  }

  setAuthentication(auth) {
    this.authenticated = auth;
  }

  changeEmail(email) {
    return this.http.post(SERVER_API_URL + `api/account/change-email?email=${email}`, {});
  }

  changePassword(pass) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    const headersOptions = {
      headers: headers
    };
    return this.http.post(SERVER_API_URL + `api/account/change-password`, pass, headersOptions);
  }
}

import {AfterViewInit, Component, ElementRef, OnInit, Renderer} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import {LoginService} from "../../admin/layout/services/login.service";
import {AuthServerProvider} from "../../admin/layout/services/auth-service";


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

    authenticationError: boolean;
    authenticationError2: boolean;
    codeStep = false;

    loginForm = this.fb.group({
        username: [''],
        password: [''],
        rememberMe: [true]
    });

    loginCodeForm = this.fb.group({
      code: [''],
    });

    loginData: any = {};

  constructor(  private router: Router, private fb: FormBuilder, private loginService: LoginService,private authService: AuthServerProvider, private renderer: Renderer, private elementRef: ElementRef) {}



  ngOnInit() {

  }

  ngAfterViewInit() {
      setTimeout(() => {
          this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#username'), 'focus', []);
          this.authService.autoAuthUser();
      }, 0);
  }





    get form() {
        return this.loginForm.controls;
    }

  getFullYear() {
    return new Date().getFullYear();
  }

//   onRememberChange(change) {
//       this.loginForm.controls['rememberMe'].patchValue(change);
//   }

  resetError() {
    if (this.authenticationError) {
       this.authenticationError = false;
    }
    if (this.authenticationError2) {
      this.authenticationError2 = false;
    }
  }

  restState(){
    this.loginCodeForm.reset();
    this.loginData = {};
    this.codeStep = false;

  }

  loginWithoutCode() {
    if (this.loginForm.invalid) {
      return;
    }
    this.loginData = {
      username: this.loginForm.get('username').value,
      password: this.loginForm.get('password').value,
      rememberMe: this.loginForm.get('rememberMe').value,
      mobileId: "FAKE_" + this.loginForm.get('username').value
    };
    this.loginService.loginWithoutCode(this.loginData)
    .subscribe((data: any) => {
      if (data.status === 200 && data.statusText === 'OK') {
        this.codeStep = true;
        this.authenticationError = false;
      } else {
        this.authenticationError = true;
        return;
      }
    }, err => {
      console.log(err);
      this.authenticationError = true;
      return;
    })
  }

  login() {
      if (this.loginCodeForm.invalid) {
          return;
      }
      this.loginService
          .login({
              username: this.loginData.username,
              password: this.loginData.password,
              rememberMe: this.loginData.rememberMe,
              mobileId: "FAKE_" + this.loginData.username,
              emailKey: this.loginCodeForm.controls['code'].value
          })
          .subscribe((data: {message: string}) => {
              if (!data || data.message === 'error') {
                 this.authenticationError2 = true;
                 return;
              }
              this.authenticationError2 = false;
              this.loginData = {};
              this.codeStep = false;

                  // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                  // since login is successful, go to stored previousState and clear previousState

                  //    this.router.navigateByUrl(redirect);

              },
              () => {
                  this.authenticationError2 = true;
                  this.codeStep = false;
                  this.loginData = {};
              }
          );
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from "./authentication/login/login.component";
import {LogGuardService} from "./authentication/guards/log.guard";
import {AuthGuardService} from "./authentication/guards/auth.guard";
import {ForgotPasswordComponent} from "./admin/layout/components/forgot-password/forgot-password.component";


const routes: Routes = [
  { path: '', canActivate: [AuthGuardService], loadChildren: () => import('../app/admin/admin.module').then(m => m.AdminModule)},
  { path: 'login', component: LoginComponent, canActivate: [LogGuardService]},
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [LogGuardService]},
  { path: '**', component: LoginComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

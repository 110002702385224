import { Injectable,} from '@angular/core';
import { Router } from '@angular/router';
import { flatMap } from 'rxjs/operators';
import { AuthServerProvider } from '../services/auth-service';
import {AccountService} from "./account.service";

@Injectable({
    providedIn: 'root'
})
export class LoginService {
  constructor(private authService: AuthServerProvider, private accountService: AccountService, private router: Router) {}


  login(credentials) {
    return this.authService.login(credentials);
  }

  loginWithoutCode(credentials) {
    return this.authService.loginWithoutCode(credentials);
  }

  logout() {
    this.authService.logout()
        .subscribe(null, null, () => {
          this.accountService.authenticate(null);
          this.accountService.setAuthentication(false);
          this.router.navigate(['/login']);
        });
  }
}

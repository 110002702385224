import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';

import { NgxWebstorageModule} from "ngx-webstorage";
import { AppComponent } from './app.component';
import { LoginComponent } from "./authentication/login/login.component";
import { ForgotPasswordComponent } from "./admin/layout/components/forgot-password/forgot-password.component";

import { TokenInterceptor } from "./core/tokenInterceptor";



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxWebstorageModule.forRoot({ prefix: 'fix', separator: '-' }),
    QuillModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [NgbActiveModal, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';


import {AccountService} from "../../admin/layout/services/account.service";

@Injectable({ providedIn: 'root' })
export class LogGuardService implements CanActivate {
  constructor(private router: Router, private accountService:  AccountService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const isAuth = this.accountService.isAuthenticated();
    if (isAuth) {
      this.router.navigate(['/dashboard']);
    }
    return !isAuth;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { map } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import {SERVER_API_URL} from "../../../app.constants";
import {AccountService} from "./account.service";
/*import { AccountService } from 'app/core/auth/account.service';
import { SERVER_API_URL } from 'app/app.constants';*/

@Injectable({ providedIn: 'root' } as any)
export class AuthServerProvider {
  loggedUser: {
    name: string;
    token: string;
  } = {
    name: '',
    token: ''
  };
  tokenTimer: any;
  tokenDuration = 36000;

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
    private router: Router

  ) {}

 /* getToken() {
    return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
  }*/

  login(credentials): Observable<any> {
    const data = {
      username: credentials.username,
      password: credentials.password,
      rememberMe: credentials.rememberMe,
      mobileId: credentials.mobileId,
      emailKey: credentials.emailKey
    };

    function authenticateSuccess(resp) {
      const bearerToken = resp.headers.get('Authorization');
      if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
          const jwt = bearerToken.slice(7, bearerToken.length);
          let tokenInfo = this.getTokenInfo(jwt);
          if (tokenInfo && tokenInfo.auth) {
              let authList = tokenInfo.auth.split(',');
              if (authList.indexOf('ROLE_ADMIN') < 0) {
                  return {message: 'error'};
                  //this.clearAndReturn();
              }



          }

          if (this.router.url.startsWith('/') || this.router.url.startsWith('/login')) {
              this.router.navigate(['/dashboard']);
          }

        this.accountService.setAuthentication(true);
        this.storeAuthenticationToken(jwt, credentials.rememberMe);
        return jwt;
      }
    }
    return this.http.post(SERVER_API_URL + 'api/authenticate', data, { observe: 'response' }).pipe(map(authenticateSuccess.bind(this)));
  }

  loginWithoutCode(credentials): Observable<any> {
    const data = {
      username: credentials.username,
      password: credentials.password,
      rememberMe: credentials.rememberMe,
      mobileId: credentials.mobileId
    };
    return this.http.post(SERVER_API_URL + 'api/authenticate', data, { observe: 'response' });
  }

  storeAuthenticationToken(jwt, rememberMe, username) {
    if (rememberMe) {
      this.$localStorage.store('authenticationToken', jwt);
      const tokenDuration =  this.tokenDuration;
      this.storeUserInfo(jwt, tokenDuration, username);
    } else {
      this.$sessionStorage.store('authenticationToken', jwt);
    }
  }

      logout(): Observable<any> {
        return new Observable(observer => {
          this.$localStorage.clear('authenticationToken');
          this.$sessionStorage.clear('authenticationToken');
          this.clearAndReturn();
          observer.complete();
        });
      }

      setAuthTimer(duration: number) {
        this.tokenTimer = setTimeout(() => {
          this.logout();
        }, duration * 10000);
      }

      private getAuthData() {
        const token = this.$localStorage.retrieve('authenticationtoken');
        const expirationDate = new Date(this.$localStorage.retrieve('expiration'));
        const userName = this.$localStorage.retrieve('login');
        if (!token || !expirationDate) {
          return;
        }
        return {
          token,
          expirationDate,
          userName
        };
      }

      autoAuthUser() {
        const authInformation = this.getAuthData();

        if (!authInformation) {
          return;
        }

        let tokenInfo = this.getTokenInfo(authInformation.token);
        if (tokenInfo && tokenInfo.auth) {
          let authList = tokenInfo.auth.split(',');
          if (authList.indexOf('ROLE_ADMIN') < 0) {
              this.clearAndReturn();
              return;
          }
        } else {
            this.clearAndReturn();
          return;
        }
        const now = new Date();
        const expiresIn = authInformation.expirationDate.getTime() - now.getTime();

        if (expiresIn > 0) {
          this.loggedUser = {
            name: authInformation.userName,
            token: authInformation.token
          };

          this.setAuthTimer(expiresIn / 1000);
          this.accountService.setAuthentication(true);
          this.router.navigate(['/dashboard']);

        }
      }

      clearAndReturn() {
          this.$localStorage.clear('authenticationToken');
          this.$sessionStorage.clear('authenticationToken');
          this.$localStorage.clear('expiration');
          this.$localStorage.clear('login');
          this.router.navigate(['/login']);
      }

      getTokenInfo(token) {
          if (token) {
              try {
                let decoded = JSON.parse(atob(token.split('.')[1]));
                  return decoded;
              } catch (e) {
                  return null;
              }
          }
      }


      storeUserInfo(token, duration, name) {
        if (token) {
          const expiresInDuration = duration;
          this.setAuthTimer(expiresInDuration);
          this.loggedUser = {
            token,
            name
          };

          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
          this.saveAuthData(expirationDate, this.loggedUser);
        }
      }

      private saveAuthData(expirationDate: Date, user: any) {
          this.$localStorage.store('expiration', expirationDate.toISOString());
      }

}

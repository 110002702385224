import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {SERVER_API_URL} from "../../../app.constants";





@Injectable({ providedIn: 'root' })
export class AdminService {
  token = '';
  private companyId = '';

  constructor(private sessionStorage: SessionStorageService, private http: HttpClient, private $localStorage: LocalStorageService,  private $sessionStorage: SessionStorageService) {}

    returnToken () {
        this.token = this.getToken();
        return this.token;
    }
    private getToken() {
      return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
    }

    getCustomers(page, size, search, order, customerId?) {
            let idSearchField = '';
            let statusSearchField = '';
            if (typeof +search.value === 'number' && +search.value) {
                idSearchField = search.value;
            } else {
                idSearchField = '';
            }
            if (search.value === 'true' || search.value === 'false') {
                statusSearchField = search.value;
            } else {
                statusSearchField = '';
            }
            return customerId ? this.http.get(SERVER_API_URL + `api/users?page=${page}&size=${size}&sort=${order.fieldName + ',' + order.dir}&id=${customerId}`) :
            this.http.get(SERVER_API_URL + `api/users?page=${page}&size=${size}&authorityName=ROLE_CUSTOMER&id=${idSearchField}&firstName=${search.value}&lastName=${search.value}&email=${search.value}&imageUrl=${search.value}&country=${search.value}&city=${search.value}&activated=${statusSearchField}&sort=${order.fieldName + ',' + order.dir}`);

    }

    getAllUsers() {
      return this.http.get( SERVER_API_URL + `api/all-users`);
    }

    getAccess(id) {
      return this.http.get( SERVER_API_URL + `api/account/generate-access/${id}`);
    }

    createCustomer(user) {
        return this.http.post(SERVER_API_URL + `api/register/customer`, user);
    }

    blockCustomer(userId, flag) {
        return this.http.get(SERVER_API_URL + `api/user-infos/block/${userId}?blockedAt=${flag}`);
    }

    activateCustomer(userId) {
        return this.http.get(SERVER_API_URL + `api/users/activated/${userId}?activated=true`);
    }

    deleteUser(user) {
        return this.http.delete(SERVER_API_URL + `api/users/${user.email}`, {responseType: 'text'});
    }

    getCompanies(companyID?, professionId?) {
       return   companyID ? this.http.get( SERVER_API_URL + `api/companies/${companyID}`) :
                professionId ? this.http.get( SERVER_API_URL + `api/companies/?professionId=${professionId}`) :
                this.http.get( SERVER_API_URL + `api/companies`);
    }

    deleteCompany(company) {
        return this.http.delete(SERVER_API_URL + `api/companies/${company.id}`, {responseType: 'text'});
    }

    getClaims() {
        return  this.http.get( SERVER_API_URL + `api/claims/get-all-with-user-info`);
    }

    updateClaimStatus(claim) {
        return  this.http.put( SERVER_API_URL + `/api/claims/`, claim);
    }

    getJobs(page, size, search, order) {
      let statusSearchField = '';
      if (search.value) {
        statusSearchField = '&messageKey.contains=' + search.value;
      }
        return this.http.get( SERVER_API_URL + `api/jobs/admin/?page=${page}&size=${size}${statusSearchField}&sort=${order.fieldName + ',' + order.dir}`);
    }

    getJobsDetails(jobId) {
        return this.http.get( SERVER_API_URL + `api/jobs/admin/details/${jobId}`);
    }

    getJobsHistory(jobId) {
        return this.http.get( SERVER_API_URL + `api/job-histories/${jobId}`);
    }

    getInvoices(jobId) {
      return this.http.get( SERVER_API_URL + `api/invoices/job/${jobId}`);
    }

    showInvoice(invoiceId) {
      return this.http.get( SERVER_API_URL + `api/invoices/pdf/${invoiceId}`);
    }

    getAllZipCodesFile() {
      return this.http.get( SERVER_API_URL + `api/zipcodes/get-all`, {responseType: 'arraybuffer'});
    }

    getAllTechniciansFile(type) {
      return this.http.get( SERVER_API_URL + `api/technicians/get-file?type=${type}`, {responseType: 'arraybuffer'});
    }

    getAllCustomersFile(type) {
      return this.http.get( SERVER_API_URL + `api/users/get-file?type=${type}`, {responseType: 'arraybuffer'});
    }

    getAllJobsFile(type) {
      return this.http.get( SERVER_API_URL + `api/jobs/admin/get-file?type=${type}`, {responseType: 'arraybuffer'});
    }

    getAllCompaniesFile(type) {
      return this.http.get( SERVER_API_URL + `api/companies/get-file?type=${type}`, {responseType: 'arraybuffer'});
    }

    getAllLocalizationFile(type) {
      return this.http.get( SERVER_API_URL + `api/localizations-new/get-file?type=${type}`, {responseType: 'arraybuffer'});
    }

    getTechCoverage(size: number) {
        return this.http.get( SERVER_API_URL + `api/tech-coverage/?&size=${size}`);
    }

    getTechCoverageZipcodes(professionId: number) {
        return this.http.get( SERVER_API_URL + `api/tech-coverage-report/${professionId}`);
    }

    getEmployees(companyId) {
        return this.http.get( SERVER_API_URL + `api/companies/employees/${companyId}`);
    }

    blockEmployee(userId, flag) {
        return this.http.get(SERVER_API_URL + `api/user-infos/block/${userId}?blockedAt=${flag}`);
    }

    getTechnicians(professionId?) {
        return  professionId ? this.http.get( SERVER_API_URL + `api/technicians/?professionId=${professionId}`) :
                this.http.get( SERVER_API_URL + `api/technicians`);
    }

    getProfessions() {
        return this.http.get( SERVER_API_URL + `api/professions?page=0&size=5000&sort=Id,asc`);
    }

    getProfession(professionId) {
        return this.http.get( SERVER_API_URL + `api/professions/${professionId}`);
    }

    getCategories() {
        return this.http.get( SERVER_API_URL + `api/categories`);
    }

    createCategory(category) {
        return this.http.post( SERVER_API_URL + `api/categories`,category);
    }

    updateCategory(category) {
        return this.http.put( SERVER_API_URL + `api/categories`,category);
    }

    deleteCategory(category) {
      return this.http.delete( SERVER_API_URL + `api/categories/${category.id}`,{responseType: 'text'});
    }

    addNewProfession(profession) {
        return this.http.post( SERVER_API_URL + `api/professions`, profession);
    }

    editProfession(profession) {
        return this.http.put( SERVER_API_URL + `api/professions`, profession);

    }

    deleteProfession(id: number) {
        return this.http.delete(SERVER_API_URL + `api/professions/${id}`, {responseType: 'text'});
    }

    getTestimonials() {
      return this.http.get( SERVER_API_URL + `api/testimonials/getAllWithFiles`);
    }

    addNewTestimonial(testimonial : {
      firstAndLastName: string,
      locations: string,
      serviceTitle: string,
      serviceDescriptions: string,
      rating: string,
      logo: File,
      photos: Array<File>}) {
      const postData = new FormData();
      postData.append('firstAndLastName', testimonial.firstAndLastName);
      postData.append('locations', testimonial.locations);
      postData.append('serviceTitle', testimonial.serviceTitle);
      postData.append('serviceDescriptions', testimonial.serviceDescriptions);
      postData.append('rating', testimonial.rating);
      postData.append('logo', testimonial.logo, testimonial.logo.name);
      for (const file of testimonial.photos) {
        postData.append('files', file as File, file.name); // file.name is optional
      }
      return this.http.post( SERVER_API_URL + `api/testimonials-custom`, postData);
    }

    deleteTestimonial(id: number) {
      return this.http.delete(SERVER_API_URL + `api/testimonials/${id}`, {responseType: 'text'});
    }

    updateTestimonial(testimonial) {
      return this.http.put(SERVER_API_URL + `api/testimonials`, testimonial);
    }

    changePhoto(photo, id, type) {
      const postData = new FormData();
      if (type === 'logo') {
        postData.append('file', photo, photo.name);
        postData.append('id', id);
        return this.http.put(SERVER_API_URL + `api/testimonials/upload-logo`, postData);
      } else {
        postData.append('file', photo, photo.name);
        postData.append('id', id);

        return this.http.put(SERVER_API_URL + `api/testimonials/upload-review`, postData);
      }

    }

    deleteTestimonialPhoto(id) {
      return this.http.delete(SERVER_API_URL + `api/files/${id}`, {responseType: 'text'});
    }



    saveCompanyId(companyId) {
      this.companyId =  companyId;
    }

    getCompanyId() {
      return this.companyId;
    }

    getTimeRates() {
        return this.http.get( SERVER_API_URL + `api/time-rates`);
    }

    updateTimeRates(timeRatesData) {
        return this.http.put(SERVER_API_URL + `api/time-rates/all`, timeRatesData);
    }

    getSettings() {
        return this.http.get( SERVER_API_URL + `api/settings`);
    }

}

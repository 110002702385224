import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from "rxjs/internal/Observable";
import {AdminService} from "../admin/layout/services/admin.service";





@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private adminService: AdminService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userToken = this.adminService.returnToken();
        if (userToken) {
            const modifiedReq = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${userToken}`),

            });
            return next.handle(modifiedReq);
        } else {
            return next.handle(req);
        }

    }
}
